import { Row, Col, Menu, Dropdown, Badge, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import cookies from 'browser-cookies';
// import logo from '@assets/hxhjblogo.png';
import logo from '@assets/lnslogo.png';
import _ from 'lodash';

import { useAppSelector, useSDKSelector } from '~/hooks';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState, useRef } from 'react';

const getRootDomain = () => {
  const [d0, d1] = window.location.hostname.split('.').reverse();
  return [d1, d0].join('.');
};

const BaseHeader = (props) => {
  const user = useAppSelector((s) => s.user);
  const messageServe = useSDKSelector('messageSDK')
  const [msg, setMsg] = useState([])
  const [msg2, setMsg2] = useState([])

  const handleMenuClick = (info) => {
    const { key } = info;
    switch (key) {
      case 'logout':
        cookies.erase('GD_AUTHINFO');
        // 清除当前用户菜单
        // localStorage.removeItem("GD_AUTHSIDERS")
        document.cookie = `GD_AUTHINFO=; domain=${getRootDomain()}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        window.location.reload();
        break;
      default:
        return console.info('不识别的动作', key);
    }
  };
  const readed = (msg) => {
    console.log('msg', msg)
    const {_id} = msg
    messageServe.readed({messages: [_id]}).then(() => {
      console.log('readed')
      refreshMsg()
    })
  }
  const useInterval = (cb: Function, time = 1000) => {
    const cbRef = useRef<Function>();
    useEffect(() => {
      cbRef.current = cb;
    });
    useEffect(() => {
      const callback = () => {
        cbRef.current?.();
      };
      const timer = setInterval(() => {
        callback();
      }, time);
      return () => clearInterval(timer);
    }, []);
  };
  const refreshMsg = () => {
    messageServe.list({readed: false, p_size: 10000}).then(msg => {
      console.log('msg', msg)
      const {messages} = msg
      setMsg(messages)
      
    })
  }
  useEffect(() => {
    setMsg2(msg)
  }, [msg])
  useEffect(() => {
    refreshMsg()
  }, [])
  useInterval(() => {
    refreshMsg()
  }, 54000)
  return (
    <Row>
      <Col span={12}>
        <img src={logo} alt="" style={{ height: '49px' }} />
        <div style={{display: 'inline-block', maxHeight: '60px', lineHeight: '60px', fontSize: '20px', color: '#0E72C5', marginLeft: '15px'}}>LNS患者病程管理</div>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Row justify='space-around'>
          <Col span={20} >
          {
            _.size(msg2) > 0 && (<Badge size="small" count={_.size(msg2)} offset={[6, 0]} overflowCount={999}><Dropdown
              overlay={
                <div className='msgwrapper'>
                  {
                    msg2.map((item:object) => (
                      <div dangerouslySetInnerHTML={{__html: item['content']}} onClick={readed.bind(this, item)}>
                      </div>
                    ))
                  }
                </div>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <a
                className="ant-dropdown-link"
                style={{ color: 'black' }}
                onClick={(e) => e.preventDefault()}
              >
                {'新消息'} {/*<DownOutlined />*/}
                
                
              </a>
            </Dropdown></Badge>)
          }
          
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
          <Dropdown
            overlay={
              <Menu style={{ minWidth: '100px' }}>
                <Menu.Item key="person"><Link to="/personcenter">个人信息</Link></Menu.Item>
                <Menu.Item key="modifypwd"><Link to="/personcenter/password">修改密码</Link></Menu.Item>
                <Menu.Item key="logout" onClick={handleMenuClick}>退出登录</Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              style={{ color: '#4E97DC' }}
              onClick={(e) => e.preventDefault()}
            >
              {_.get(user, 'userName')} <DownOutlined />
            </a>
          </Dropdown>
          </Col>
        </Row>
        
      </Col>
    </Row>
  );
};

export default <BaseHeader />;
