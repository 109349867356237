import GDRequest from '@common/gdrequest';

const caseSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/configapi`;

  return {
    get: (query) => GDRequest.get(base, `${serve}/config/`, query).send(),
    downloadByPath: (path) => GDRequest.get(`${path}`, '').blob().send()
  };
};
export default caseSDK;
