import GDRequest from '@common/gdrequest';

const loggerSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/loggers/`, query).send(),
    update: (data) => GDRequest.post(base, `${serve}/loggers/`, data).send(),
    detectionlist: (detection_id, query) => GDRequest.get(base, `${serve}/detections/${detection_id}/loggers/`, query).send(),
    createdetectionlogger: (detection_id, payload) => GDRequest.post(base, `${serve}/detections/${detection_id}/loggers/`, payload).send(),
  };
};
export default loggerSDK;
