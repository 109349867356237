import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {} as any,
  reducers: {
    updatePagination: (state, action) => {
      const route = _.get(action, 'payload.path');
      if (route) {
        _.set(state, `${route}`, _.assign(_.get(state, `${route}`), _.get(action, 'payload.data')));
      }
    }
  },
});

export const { updatePagination } =
paginationSlice.actions;
export default paginationSlice.reducer;
