import GDRequest from '@common/gdrequest';

export const makeSampleSDK = (GDRequest) => (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;
  const limsServe = `/lims/${account}`;
  const configsServe = `/configs/${account}`;
  const other = `/accounts/${account}/projects/${project}/configs/${account}/dataconfig`

  return {
    // list: (query) => GDRequest.get(base, `${serve}/samples/`, query).send(),
    list: (query) => GDRequest.get(base, `${limsServe}/samples/`, query).send(),
    listSpecimen: (query) => GDRequest.get(base, `${other}`, query).send(),
    listVessel: (query) => GDRequest.get(base, `${other}`, query).send(),
    create: (payload) =>
      GDRequest.post(base, `${limsServe}/samples/`, payload).send(),
    update: (id, payload) =>
      GDRequest.post(base, `${limsServe}/samples/${id}`, payload).send(),
    delete: (id, payload) =>
      GDRequest.post(base, `${limsServe}/samples/${id}/delete`, payload).send(),
    addRule: (payload) =>
      GDRequest.post(base, `${serve}/detections/specimen/rules/`, payload).send(),
    getRuleInfo: (_id) => GDRequest.get(base, `${serve}/detections/specimen/rules/${_id}/`).send(),
    updateRuleInfo: (_id, payload) => GDRequest.put(base, `${serve}/detections/specimen/rules/${_id}/`, payload).send(),
    getRules: (query) =>
      GDRequest.get(base, `${serve}/detections/specimen/rules/`, query).send(),
    delRule: (id) => GDRequest.del(base, `${serve}/detections/specimen/rules/${id}/`).send(),
    delRulePost: (id) => GDRequest.post(base, `${serve}/detections/specimen/rules/${id}/delete/`).send(),
    updateDefaultRule: (_id, payload) => GDRequest.put(base, `${serve}/detections/specimen/defaultrules/${_id}/`, payload).send(),
    getDefaultRules: (query) => GDRequest.get(base, `${serve}/detections/specimen/defaultrules/`, query).send(),
    getDefaultRuleInfo: (_id) => GDRequest.get(base, `${serve}/detections/specimen/defaultrules/${_id}/`).send(),
    // 筛选待检测样品
    getPreDetectionSample: (query) => GDRequest.post(base, `${serve}/detections/screenspecimens/`, query).send(),
    getPreDetectionSamples: (query) => GDRequest.get(base, `${serve}/detections/screenspecimens/`, query).send(),
    saveOrder: (payload) => GDRequest.post(base, `${serve}/detections/`, payload).send(),
    updateOrder: (_id, payload) => GDRequest.put(base, `${serve}/detections/${_id}/`, payload).send(),
    getOrderInfo: (_id) => GDRequest.get(base, `${serve}/detections/${_id}/`).send(),
    
    getSpecimens: (_id) => GDRequest.get(base, `${serve}/detections/${_id}/specimens/`).send(),
    delSpecimens: (_id) => GDRequest.del(base, `${serve}/detections/specimens/${_id}/`).send(),
    updateSpecimens: (_id, data) => GDRequest.put(base, `${serve}/detections/specimens/${_id}/`, data).send(),
    delSpecimensPost: (_id) => GDRequest.post(base, `${serve}/detections/specimens/${_id}/delete/`).send(),
    // 导出列表
    exportList: (type, payload) => GDRequest.post(base, `${serve}/v1/export/${type}/`, payload).send(),
    getExportList: (query) => GDRequest.get(base, `${serve}/v1/export/tasks/`, query).send(),

    getFile: (filename) => GDRequest.get(base, `${serve}/files/${filename}/`).blob().send(),
    delFile: (filename) => GDRequest.del(base, `${serve}/files/${filename}/`).send(),
    delFilePost: (filename) => GDRequest.post(base, `${serve}/files/${filename}/delete/`).send(),
    addBatchSpecimentOut: (data) => GDRequest.post(base, `${limsServe}/batchspecimentout`, data).send()
  };
};

export default makeSampleSDK(GDRequest);
