import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import configReducer from './slices/configSlice';
import recorderSlice from '@slices/recorderSlice';
import pagedataReducer from '@slices/pagedataSlice';
import paginationReducer from '@slices/paginationSlice';
import filtersReducer from '@slices/filtersSlice';

const store = configureStore({
  preloadedState: {
    config: {
      formDependencyFileUploadPath: '/home/<%= userName %>/',
    },
  },
  reducer: {
    user: userReducer,
    config: configReducer,
    recorder: recorderSlice,
    pagedata: pagedataReducer,
    pagination: paginationReducer,
    filters: filtersReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
