import GDRequest from '@common/gdrequest';

const scienceSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/sciences/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/sciences/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/sciences/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/sciences/${id}/`, payload).send(),
    // 导出
    export: (payload) => GDRequest.get(base, `${serve}/sciences/export/`, payload).blob().send(),
  };
};
export default scienceSDK;
