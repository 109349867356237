import React, { Suspense, useEffect, useContext } from 'react';
import _ from 'lodash';

import NoticeContext from '@containers/NoticeContext';
import RequireAuth from '@pages/auth/RequireAuth';
import { Routes, Route, useLocation, Outlet } from 'react-router-dom';

const TriggerNotice = React.memo(
  (props: { path: string; emits: {} }) => {
    const { emits } = props;
    const context = useContext(NoticeContext);
    const location = useLocation();

    useEffect(() => {
      context.emit('CustomRouteChange');
      if (!_.isEmpty(emits))
        _.forEach(emits, ({ name, payload }) => context.emit(name, payload));
    }, [location, context, emits]);
    return null;
  },
  (prev, next) => prev.path === next.path
);

const Empty = () => <div>空</div>;

const renderRoute = (route, parent) => {
  const { wrapper: Wrapper, wrapperProps = {}, element } = route;
  const { path = '', children, props = {}, emits } = route;
  const tidyPath = `${path}`.replace(/^\//, '').replace(/\/$/, '');
  const current = parent.concat([tidyPath]); // snippet
  const fullPath = current.join('/');
  const LazyElement = element
    ? element.then
      ? React.lazy(() => element)
      : element
    : Empty;

  if (Wrapper) {
    return [
      <Route
        key={path + 'wrapper'}
        path={[tidyPath, '*'].join('/')}
        element={
          <RequireAuth route={route}>
            <Wrapper routes={children} {...wrapperProps}>
              <Outlet />
            </Wrapper>
          </RequireAuth>
        }
      >
        {_.reduce(children, (t, route) => t.concat(renderRoute(route, [])), [])}
      </Route>,
    ];
  }

  const routes = [
    <Route
      key={path}
      path={fullPath}
      element={
        <RequireAuth route={route}>
          <Suspense fallback="loading">
            <TriggerNotice path={fullPath} emits={emits} />
            <LazyElement {...props} />
          </Suspense>
        </RequireAuth>
      }
    />,
  ].concat(
    _.reduce(children, (t, route) => t.concat(renderRoute(route, current)), [])
  );

  return routes;
};

interface CustomRouterProps {
  routes: any[];
}

const CustomRouter = (props: CustomRouterProps) => {
  const { routes } = props;

  return (
    <Routes>
      {_.reduce(routes, (t, route) => t.concat(renderRoute(route, [])), [])}
      <Route path="*" element="nomatch" />
    </Routes>
  );
};

CustomRouter.propTypes = {};

export default CustomRouter;
