import GDRequest from '@common/gdrequest';

const rolesSDK = (base, account, project) => {
  const serve = `/accounts/${account}`;
  // /accounts/<account_name>/policies/
  return {
    list: (query) => {
      return GDRequest.get(base, `${serve}/policies/`, query).send().then(res => {
          return {
            policies_list: res
          }
        })
    },
    // 创建/编辑角色并赋予权限
    add: (params) => GDRequest.put(base, `${serve}/policies/${params.path}/`, params.data).send(),
    getPolicies: (path) => GDRequest.get(base, `${serve}/policies/${path}/`, {}).send(),
  };
};
export default rolesSDK;
