import GDRequest from '@common/gdrequest';
import _ from 'lodash';

export const makeFormSDK = (GDRequest) => (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    get: (id) => GDRequest.get(base, `${serve}/forms/${id}/`).send(),
    has: (name) =>
      GDRequest.get(base, `${serve}/forms/`, { name })
        .send()
        .then(
          (res) =>
            _.has(res, 'results.0.name') &&
            _.get(res, 'results.0.name') === name
        ),
    del: (id) => GDRequest.del(base, `${serve}/forms/${id}/`).send(),
    delPost: (id) => GDRequest.post(base, `${serve}/forms/${id}/delete/`).send(),
    list: (query) => GDRequest.get(base, `${serve}/forms/`, query).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/forms/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/forms/${id}/`, payload).send(),
    publish: (id) =>
      GDRequest.put(base, `${serve}/forms/${id}/`, { status: '启用中' }).send(),
    enable: (id) =>
      GDRequest.put(base, `${serve}/forms/${id}/`, { status: '启用中' }).send(),
    disable: (id) =>
      GDRequest.put(base, `${serve}/forms/${id}/`, { status: '已停用' }).send(),
    components: () => GDRequest.get(base, `${serve}/forms/components/`).send(),
    saveComponent: (payload) =>
      GDRequest.post(base, `${serve}/forms/components/`, payload).send(),
  };
};

export default makeFormSDK(GDRequest);
