import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { Input, Card, Form, Button, message } from 'antd';
import cookies from 'browser-cookies';
import { buildGDAuthInfoBySigninResult, buildGDAuthSiderByUserSiders, formatMenus } from '@pages/auth/util';
import _ from 'lodash';

import { useAppDispatch } from '~/hooks';
import AuthSDK from '@common/sdk/auth';
import userSiderSDK from '@common/sdk/auth/usersiders';
import { login, userSiders } from '@slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const Signin = (props) => {
  const { redirect } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSigninSuccess = useCallback(
    (result) => {
      const userName = _.get(result, 'user.user_name');
      const userInfo = _.get(result, 'user.user_info');
      const accountName = _.get(result, 'user.account_name');

      // 存储左侧菜单  获取当前用户可见菜单list
      const ciphertext = buildGDAuthInfoBySigninResult(result);
      cookies.set('GD_AUTHINFO', ciphertext.toString());
      dispatch(login({ userName, accountName, userInfo }));
      userSiderSDK(`${window.location.origin}`, accountName, 'default').getSiders(userName, {name: accountName}).then(res => {
        console.log('res', res)
        const {menu_list} = res.data
        const siders = formatMenus(menu_list)
        console.log('siders', siders)
        // return
        setLoading(true);
        // dispatch(login({ userName, accountName, userInfo }));
        dispatch(userSiders({ siders }));
        navigate(redirect || '/');
      })
    },
    [dispatch, navigate]
  );

  const handleLogin = useCallback(() => {
    const resolve = (info) => {
      if (_.has(info, 'errorFields')) return;
      const auth = AuthSDK(`${window.location.origin}/`, '', '');

      setLoading(true);
      if (info.password) {
        const okey = 'genedock.gateway';
        const ciphertext = CryptoJS.AES.encrypt(info.password, okey).toString();
        info.password = ciphertext;
      }
      auth.signin(info).then(handleSigninSuccess, (error) => {
        message.error('登录失败, 用户名或密码错误');
        setLoading(false);
      })
    };

    form.validateFields().then(resolve);
  }, [form, handleSigninSuccess]);

  useEffect(() => {
    const gdauthinfo = cookies.get('GD_AUTHINFO');
    // const auth = AuthSDK(`${window.location.origin}/`, '', '');
    // auth.getLabEndpoint().then((res) => {
    //   console.info('res', res);
    //   window.location.href = res;
    // })
    if(!gdauthinfo) window.location.href = `${window.location.origin}/lab/`;
    
  }, []);

  return '';
  // return (
  //   <div className="loginwrapper">
  //   <Card
  //     title="登录"
  //     style={{ width: '400px', height: '305px', margin: 'auto', position: 'absolute', left: '0', right: '0', top: '0', bottom: '0' }}
  //     onKeyUp={(e) => e.key === 'Enter' && handleLogin()}
  //   >
  //     <Form form={form}>
  //       <Form.Item
  //         label="账户"
  //         name="account"
  //         labelCol={{ span: 6 }}
  //         initialValue=""
  //         rules={[{ required: true, message: '请填写账户' }]}
  //       >
  //         <Input />
  //       </Form.Item>
  //       <Form.Item
  //         label="用户名"
  //         name="username"
  //         labelCol={{ span: 6 }}
  //         initialValue=""
  //         rules={[{ required: true, message: '请填写用户名' }]}
  //       >
  //         <Input />
  //       </Form.Item>
  //       <Form.Item
  //         label="密码"
  //         name="password"
  //         labelCol={{ span: 6 }}
  //         initialValue=""
  //         rules={[{ required: true, message: '请填写密码' }]}
  //       >
  //         <Input.Password />
  //       </Form.Item>
  //     </Form>
  //     <Button
  //       type="primary"
  //       loading={loading}
  //       onClick={handleLogin}
  //       style={{ width: '100%' }}
  //     >
  //       登录
  //     </Button>
  //   </Card>
  //   </div>
  // );
};

export default Signin;
