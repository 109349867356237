import GDRequest from '@common/gdrequest';

const messageSDK = (base, account, project) => {
  const serve = ``;
  return {
    list: (query) => {
      return GDRequest.get(base, `${serve}/notify/messages`, query).send()
    },
    readed: (ids) => {
      return GDRequest.post(base, `${serve}/notify/messages/readed`, ids).send()
    }
  };
};
export default messageSDK;
