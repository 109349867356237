import GDRequest from '@common/gdrequest';

const policySDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/policies/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/policies/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/policies/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/policies/${id}/`, payload).send(),
  };
};
export default policySDK;
