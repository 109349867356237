import GDRequest from '@common/gdrequest';

export const makeClinicSDK = (GDRequest) => (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/clinics/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/clinics/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/clinics/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/clinics/${id}/`, payload).send(),
    delete: (id) =>
      GDRequest.post(base, `${serve}/clinics/${id}/delete/`).send(),
  };
};

export default makeClinicSDK(GDRequest);
