import GDRequest from '@common/gdrequest';

const authProjectUsersSDK = (base, account, project) => {
  const serve = `/accounts/${account}`;

  return {
    // list: (query) => GDRequest.get(base, `${serve}/policies/`, query).send(),
    // 获取当前项目已授权的用户
    // list: (params) => GDRequest.get(base, `${serve}/authorized_policies/`+ window.btoa(`projects/`+params.path+`/`), params.data).send(),
    list: (params) => GDRequest.get(base, `${serve}/authorized_accounts/`+params.path+'/').send()
    .then(data => {
      let temp = {}
      const {account_info_list} = data.data
      if(account_info_list instanceof Array) {
        temp = { accounts: account_info_list }
      }
      return temp
    }),
    // list: (params) => GDRequest.get(base, `${serve}/projects/${project}/hxhjb/auth/users/`+params.path+`/`).send(),
    // 创建policy
    create: (params) => GDRequest.put(base, `${serve}/policies/${params.path}/`, params.data).send(),
    // 获取当前policy
    get: (params) => GDRequest.get(base, `${serve}/policies/${params.path}/`, params.data).send(),
    // 授权访问用户
    authPut: (params) => GDRequest.put(base, `${serve}/users/${params.user}/policies/${params.path}/`, params.data).send(),
    // /accounts/<account_name>/users/<user_name>/policies/<policy_name>/
    // 授权访问用户
    deleteAuth: (params) => GDRequest.del(base, `${serve}/users/${params.user}/policies/${params.path}/`).send(),// participant
    deleteAuthPost: (params) => GDRequest.post(base, `${serve}/users/${params.user}/policies/${params.path}/delete/`).send(),// participant
    getJoinUsers: ({id}) => {
      console.info('id', id)
      return GDRequest.get(base, `${serve}/projects/${project}/hxhjb/projects/${id}/`).send()
      .then(({ participant }) => {
        let temp = {}
        console.info('getJoinUsers-participant', participant, temp)
        if(participant && typeof participant === 'string') {
          temp = { list: participant.split(',') }
        }
        if(participant instanceof Array) {
          temp = { list: participant }
        }
        return temp
      })
    },
  };
};
export default authProjectUsersSDK;
