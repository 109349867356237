import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@containers/Icon';
import style from '@styles/sidebar.module.css';
const { Sider } = Layout;

const renderMenuItem = (item) => {
  const { to, title, link, icon, key, sub } = item;
  const eKey = key || to || link || title;

  if (!_.isEmpty(sub))
    return (
      // <Menu.SubMenu key={eKey} title={title}>
      <Menu.SubMenu key={eKey} title={(
        <span style={{color: '#fff', fontSize: '14px', fontWeight: '300'}}>
          {/* <i className={`icon-${icon} iconfont`} style={{width: '16px', height: '16px', marginRight: '5px'}} /> */}
          {icon && <Icon type={icon} style={{ marginRight: '5px' }} />}
          {title}
        </span>
      )}>
        

        {_.map(sub, renderMenuItem)}
      </Menu.SubMenu>
    );

  return (
    <Menu.Item key={eKey}>
      {to && (
        <Link to={to}>
          {icon && <Icon type={icon} style={{ marginRight: '5px' }} />}
          <span style={{fontWeight: '300'}}>{title}</span>
        </Link>
      )}
      {!to && !link && title}
      {link && (
        <a href={link}>
          {icon && <Icon type={icon} style={{ marginRight: '5px' }} />}
          <span style={{fontWeight: '300'}}>{title}</span>
        </a>
      )}
    </Menu.Item>
  );
};

interface SidebarProps {
  collapsed?: boolean;
  menu?: any;
}

const Sidebar = (props: SidebarProps) => {
  const { menu } = props;
  const { items: menuItems, ...menuRest } = menu;
  const match = useLocation();
  let path = match.pathname
  // 处理特殊情况下，左侧菜单路径匹配选中
  const reg = /^\/collect\/follow/
  const reg2 = /^\/collect\/cases/
  const reg3 = /^\/collect\/list/
  const patharr = path.split('/')
  if(_.size(patharr) >= 4 && (reg.test(path) || reg2.test(path) || reg3.test(path))) {
    path = path.substring(0, path.lastIndexOf('/'))
  }
  //
  const [collapsed, setCollapsed] = useState(props.collapsed);
  console.log('menuItems', menuItems)
  const [openKeys, setOpenKeys] = useState([''])

  const getDeep = (item) => {
    return _.find(item['sub'], o => {
      if(o['sub']) getDeep(o);
      return o['to'] === path
    })
  } 
  useEffect(() => {
    if(menuItems) {
      const curOpenSub = _.find(menuItems, item => (getDeep(item)));
      curOpenSub && setOpenKeys([curOpenSub['title']])
    }
  }, [menuItems])

  useEffect(() => {
    if (props.collapsed !== collapsed) setCollapsed(props.collapsed);
  }, [props.collapsed, collapsed]);

  const chaneOpenKeys = (e) => setOpenKeys(e);

  return (
    <Sider
      collapsed={collapsed}
      collapsedWidth={0}
      onCollapse={setCollapsed}
      className={style['sidebar']}
      width={168}
    >
      <Menu selectedKeys={[path]} onOpenChange={chaneOpenKeys} openKeys={openKeys} {...menuRest}>{_.map(menuItems, renderMenuItem)}</Menu>
    </Sider>
  );
};

export default Sidebar;
