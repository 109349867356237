import _ from 'lodash';
import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Recorder from 'recorder-core';
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';

const makeManager = () => {
  const manager = Recorder({
    //本配置参数请参考下面的文档，有详细介绍
    type: 'mp3',
    sampleRate: 16000,
    bitRate: 16, //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
    onProcess: function (
      buffers,
      powerLevel,
      bufferDuration,
      bufferSampleRate,
      newBufferIdx,
      asyncEnd
    ) {
      //录音实时回调，大约1秒调用12次本回调
      //可实时绘制波形（extensions目录内的waveview.js、wavesurfer.view.js、frequency.histogram.view.js扩展功能）
      //可利用extensions/sonic.js扩展实时变速变调，此扩展计算量巨大，onProcess需要返回true开启异步模式
      //可实时上传（发送）数据，配合Recorder.SampleData方法，将buffers中的新数据连续的转换成pcm上传，或使用mock方法将新数据连续的转码成其他格式上传，可以参考文档里面的：Demo片段列表 -> 实时转码并上传-通用版；基于本功能可以做到：实时转发数据、实时保存数据、实时语音识别（ASR）等
    },
  });

  return manager;
};

export const recorderSlice = createSlice({
  name: 'recorder',
  initialState: {
    isOpen: Recorder.IsOpen(),
    recording: false,
    pausing: false,
    manager: null,
    caseServe: null,
  } as any,
  reducers: {
    start: (state) => {
      const { manager } = state;
      manager.start();
      return { ...state, blob: null, recording: true };
    },
    pause: (state) => {
      const { manager } = state;
      manager.pause();
      return { ...state, pausing: true, recording: true };
    },
    resume: (state) => {
      const { manager } = state;
      manager.resume();
      return { ...state, pausing: false, recording: true };
    },
    choose: (state, action) => {
      const { caseData, caseServe } = action.payload;
      return { ...state, currentCase: caseData, caseServe };
    },
    save: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(open.pending, (state, action) => {
        console.info('open-pending');
      })
      .addCase(open.fulfilled, (state, action) => {
        state.manager = action.payload;
        state.isOpen = true;
      })
      .addCase(open.rejected, (state, action) => {
        state.isOpen = false;
        state.allowMsg = action.payload;
      });

    builder
      .addCase(stop.pending, (state, action) => {
        console.info('stop-pending', state);
      })
      .addCase(stop.fulfilled, (state, action) => {
        state.blob = action.payload;
        state.manager = null;
        state.isOpen = false;
        state.recording = false;
        state.pausing = false;
      })
      .addCase(stop.rejected, (state, action) => {
        state.manager = null;
        state.isOpen = false;
        state.recording = false;
        state.pausing = false;
      });

    builder
      .addCase(save.pending, (state, action) => {
        console.info('save-pending');
      })
      .addCase(save.fulfilled, (state, action) => {
        state.message = action.payload;
      })
      .addCase(save.rejected, (state, action) => {
        state.blob = null;
        console.info('save rejected', action.payload);
      });
  },
});

export const open = createAsyncThunk('recorder/open', () => {
  const manager = makeManager();
  return new Promise((resolve, reject) => {
    manager.open(
      () => {
        /**
         * 打开麦克风授权获得相关资源
         * dialog && dialog.Cancel(); 如果开启了弹框，此处需要取消
         * rec.start()
         * 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作
         * 通过两次用户操作来分别调用open和start是推荐的最佳流程
         * success && success();
         */
        resolve(manager);
      },
      (msg, isUserNotAllow) => {
        /**
         * 用户拒绝未授权或不支持
         * dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
         */
        reject({ msg, isUserNotAllow });
      }
    );
  });
});

export const save = createAsyncThunk('recorder/save', (blob: any, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  return new Promise((resolve, reject) => {
    const state = getState();
    const { currentCase, caseServe } = _.get(state, 'recorder');
    const caseId = _.get(currentCase, 'case_id');
    const caseNumber = _.get(currentCase, 'case_number');
    if (!currentCase || !caseServe) return reject();

    blob.name = `${caseNumber}_采样记录_${moment().format(
      'YYYYMMDDHHmmss'
    )}.mp3`;
    caseServe.addFile(caseId, 'default', blob).then(() => {
      resolve('采样文件保存成功' as any);
    }, reject);
  });
});

export const stop = createAsyncThunk(
  'recorder/close',
  (manager: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    return new Promise((resolve, reject) => {
      manager.stop(
        (blob, duration) => {
          /**
           *  console.log(blob,(window.URL||webkitURL).createObjectURL(blob),"时长:"+duration+"ms");
           *  rec.close();
           * 释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音
           * 最佳操作是录完就close掉
           * rec=null;
           * 已经拿到blob文件对象想干嘛就干嘛：立即播放、上传
           */
          /**
           * 立即播放例子】
           * var audio=document.createElement("audio");
           * audio.controls=true;
           * document.body.appendChild(audio);
           * 简单利用URL生成播放地址，注意不用了时需要revokeObjectURL，否则霸占内存
           * audio.src=(window.URL||webkitURL).createObjectURL(blob);
           * audio.play();
           */
          dispatch(save(blob));
          manager.close();
          resolve(blob);
        },
        (msg) => {
          /**
           * rec.close(); //可以通过stop方法的第3个参数来自动调用close
           * rec = null;
           */
          console.log('录音失败:' + msg);
          manager.close();
          reject();
        }
      );
    });
  }
);

export const follow = createAsyncThunk('recorder/reboot', (p, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;

  return new Promise((resolve, reject) => {
    const store = getState();
    const { isOpen, recording, pausing, manager } = _.get(store, 'recorder');

    if (recording || pausing) {
      dispatch(stop(manager)).then(() => {
        return dispatch(open()).then(() => resolve('ok' as any), reject);
      }, reject);
    } else if (!isOpen) {
      dispatch(open()).then(() => resolve('ok' as any), reject);
    }
  });
});

export const { start, pause, resume, choose } = recorderSlice.actions;
export default recorderSlice.reducer;
