import cookies from 'browser-cookies';
import CryptoJS from 'crypto-js';

import { HashRouter } from 'react-router-dom';
import { useEffect } from 'react'
import NoticeContext, { Notice } from '@containers/NoticeContext';
import CustomRouter from '@components/CustomRouter';

import routes from '@projects/router';
import ModalManager from '@containers/ModalManager';
import { DrawManager } from '@components/Drawer';
import { useAppDispatch } from '~/hooks';
import { login, userSiders } from '@slices/userSlice';
import { buildGDAuthSiderByUserSiders, formatMenus } from '@pages/auth/util';
import userSiderSDK from '@common/sdk/auth/usersiders';

import '@styles/iconfont/iconfont.css';

const notice = new Notice();

function App() {
  console.log('App')
  const dispatch = useAppDispatch();
  const gdauthinfo = cookies.get('GD_AUTHINFO');
  const gdauthsiders = localStorage.getItem('GD_AUTHSIDERS');
  if (gdauthinfo) {
    try {
      const decrypted = CryptoJS.AES.decrypt(gdauthinfo, 'genedock.cn');
      const text = decrypted.toString(CryptoJS.enc.Utf8);
      const info = JSON.parse(text);
      // 每次刷新都请求获取菜单接口
      userSiderSDK(`${window.location.origin}`, info.account, 'default').getSiders(info.name, {name: info.account}).then(res => {
        console.log('res', res)
        const {menu_list} = res.data
        const siders = formatMenus(menu_list)
        // let sidersmenu = menu_list
        // const siders = sidersmenu.map(item => {
        //   if(item['link']) item['link'] = `/lab${item['link']}`;
        //   return item
        // })
        console.log('App-siders', siders)
        dispatch(userSiders({ siders }));
      })
      const { name: userName, account: accountName, user } = info;
      dispatch(login({...info, userName, accountName, userInfo: user }));
      // dispatch(login(info));
      // dispatch(userSiders(siders))
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <HashRouter>
      <NoticeContext.Provider value={notice}>
        <CustomRouter routes={routes} />
        <DrawManager />
        <ModalManager />
      </NoticeContext.Provider>
    </HashRouter>
  );
}

export default App;
