import GDRequest from '@common/gdrequest';

const LabDetectionSDK = (base, account) => {
  const serve = `/lims/${account}`;
  return {
    list: (query) => GDRequest.get(base, `${serve}/requests`, query).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/requests`, payload).send(),
  };
};

export default LabDetectionSDK;
