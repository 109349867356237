import GDRequest from '@common/gdrequest';

const memberSDK = (base, account, project) => {
  const serve = `/account/${account}`;
  const serve2 = `/accounts/${account}`;
  const serve3 = `/accounts/${account}/projects/${project}`;

  return {
    list: (query) => GDRequest.get(base, `${serve3}/users/`, query).send().then(res => {
      const data = res.data
      return data
    }),
    add: (user) => GDRequest.put(base, `${serve3}/users/${user.name}/`, user).send(),
    // add: (user) => GDRequest.post(base, `${serve}/users/`, user).send(),
    
    get: (username) => {
      // const url = `/${account}/users/${username}`;
      return GDRequest.get(base, `${serve3}/users/${username}/`).send()
    },
    // 用户启用停用
    OnOff: (user) => GDRequest.put(base, `${serve3}/users/${user.name}/`, user).send(),
    // getv2: (uid) => {
    //   const url = `/account/v2/users/${uid}`;
    //   return GDRequest.get(base, url).send()
    // },
    // update: (user, username) => {
    //   return GDRequest.post(base, `${serve}/users/${username}`, user).send()
    // },
    checkuser: (username, passwords) => {
      return GDRequest.get(base, `${serve3}/users/${username}/login/${passwords}/`).send()
    },
    passwordv1: (username, passwords) => {
      return GDRequest.put(base, `${serve3}/users/${username}/password/${passwords}/`).send()
    },
    // passwordv1: (username, passwords) => {
    //   return GDRequest.post(base, `${serve}/users/${username}/password`, passwords).send()
    // },
    // password: (passwords) => {
    //   const url = `/account/v2/password`;
    //   return GDRequest.post(base, url, passwords).send()
    // },
    // 获取当前policy
    getPolicy: (params) => GDRequest.get(base, `${serve2}/policies/${params.path}/`, params.data).send(),
    // 授权访问用户
    // 获取当前用户的角色
    getUserPolicy: (username) => {
      return GDRequest.get(base, `${serve2}/users/${username}/authorized_policies/`, {current_account: account}).send()
      // return GDRequest.get(base, `${serve2}/users/${username}/policies/`).send()
    },
    // 授权访问用户
    authPut: (params) => GDRequest.put(base, `${serve2}/users/${params.user}/policies/${params.path}/`, params.data).send(),
    // /accounts/<account_name>/users/<user_name>/policies/<policy_name>/
    // 删除授权访问用户
    deleteAuth: (params) => GDRequest.del(base, `${serve2}/users/${params.user}/policies/${params.path}/`).send(),
    deleteAuthPost: (params) => GDRequest.post(base, `${serve2}/users/${params.user}/policies/${params.path}/delete/`).send(),
  };
};
export default memberSDK;
