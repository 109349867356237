import Signin from '@pages/auth/Signin';
import BaseLayout from '@containers/layout/BaseLayout';
import Header from '@containers/layout/BaseHeader';

// redirect referer
const routes = [
  { path: 'signin', element: Signin, props: { redirect: '/collect/forms/' } },
  {
    auth: true,
    wrapper: BaseLayout,
    wrapperProps: { headerSlot: Header },
    children: [
      {
        breadcrumbName: '问卷发布',
        path: 'questions',
        element: import('@pages/questions/index'),
        children: [
          {
            breadcrumbName: '新建问卷',
            path: 'builder',
            element: import('@pages/questions/form/FormEditer'),
            emits: [
              {
                name: 'ChangeLayoutState',
                payload: { siderbar: false, breadcrumb: false },
              },
            ],
          },
          {
            breadcrumbName: '问卷编辑',
            path: 'builder/:id',
            element: import('@pages/questions/form/FormEditer'),
            emits: [
              {
                name: 'ChangeLayoutState',
                payload: { siderbar: false, breadcrumb: false },
              },
            ],
          },
          {
            breadcrumbName: '问卷详情',
            path: ':id',
            element: import('@pages/questions/form/FormShow')
          }
        ]
      },
      {
        breadcrumbName: '个人信息',
        path: 'personcenter',
        element: import('@pages/personcenter/index'),
      },
      {
        breadcrumbName: '修改密码',
        path: 'personcenter/password',
        element: import('@pages/personcenter/password'),
      },
      {
        breadcrumbName: '编辑信息',
        path: 'personcenter/editinfo',
        element: import('@pages/personcenter/editinfo'),
      }
    ],
  },
];
export default routes;
