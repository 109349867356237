import GDRequest from '@common/gdrequest';

const caseSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/detections/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/detections/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/detections/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/detections/${id}/`, payload).send(),
    auditing: (ids, status, payload = {}) =>
      GDRequest.put(base, `${serve}/detections/auditing/status/`, {
        status,
        detection_ids: ids,
        ...payload,
      }).send(),
    // 批量导入检测
    detectionsByFile: (file) => {
      const formData = new FormData();
      formData.append('detection_file', file);
      return GDRequest.post(
        base,
        `${serve}/detections/import/`,
        formData
      ).blob().sendForm();
    },
    // 下载数据模版
    exportTemp: () => GDRequest.get(base, `${serve}/detections/import/template/`).blob().send(),
    
  };
};
export default caseSDK;
