import GDRequest from '@common/gdrequest';

const FollowSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/follows/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/follows/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/follows/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/follows/${id}/`, payload).send(),
    logger: (id, payload) =>
      GDRequest.post(base, `${serve}/follows/${id}/loggers/`, payload).send(),
    // 导入随访任务列表
    taskList: (account, payload) => {
      // return (dispatch) => {
        const url = `${serve}/v1/import/tasks/`;

        // return GDRequest.get(url, payload, '', 'account').send().then(result => {
        //   return result;
        // })
        return GDRequest.get(base, `${url}`, payload).send()
      // }
    },
    submitTask: (account, payload) => {
      // return (dispatch) => {
        const url = `${serve}/v1/import/${payload.import_type}/`;
        // return GDRequest.post(url, payload, '', 'account').send().then(result => {
        //   return result;
        // })
        return GDRequest.post(base, `${url}`, payload).send()
      // }
    },
    importTypes: (query) => GDRequest.get(base, `${serve}/v1/import_types/`, query).send(),
    downloadByPath: (path) => GDRequest.get(`${path}`, '').blob().send(),
  };
};
export default FollowSDK;
