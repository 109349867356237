import GDRequest from '@common/gdrequest';
import _ from 'lodash';

export const makeFormSDK = (GDRequest) => (base, account, project) => {
  const serve = `/lims/${account}`;

  return {
    get: (id) => GDRequest.get(base, `${serve}/questions/${id}`).send(),
    listSubjects: (id, query) => GDRequest.get(base, `${serve}/questions/${id}/subjects`, query).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/questions`, payload).send(),
    update: (id, payload) =>
      GDRequest.post(base, `${serve}/questions/${id}`, payload).send(),
    export: (id, payload) =>
      GDRequest.post(base, `${serve}/questions/${id}/export`, payload).blob().send(),
    publish: (payload) =>
      GDRequest.post(base, `${serve}/questions/publish`, payload).send()
  };
};

export default makeFormSDK(GDRequest);
