import React, { useState } from 'react';
import { Drawer, Button, Popconfirm } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import _ from 'lodash';

let manager: any = null;

export class DrawManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawers: []
    };
  }

  componentWillMount() {
    manager = this;
  }

  push(drawElement) {
    const drawers = this.state['drawers'];
    drawers.push(drawElement);
    this.setState({drawers});
  }

  pop() {
    const drawers = this.state['drawers'];
    const poped = drawers.pop();
    this.setState({drawers});
    return poped;
  }

  render() {
    const drawers = this.state['drawers'];
    return (
      <div id="draw_container">
        {
          drawers.map(D => <D key={_.uniqueId()} />)
        }
      </div>
    );
  }
}

const showDraw = props => (content, actions) => {
  const DrawerFun = () => {
    const [visible, setVisible] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    return (
      <Drawer {...props}
        visible={visible}
        onClose={() => setVisible(false)}
        afterVisibleChange={(v) => {
          if (!v && manager) {
            manager.pop();
          }
        }}>
        <div style={{marginBottom: actions ? 70 : 0}}>
          {content}
        </div>
        {
          actions ? (
            <div className="align-right"
              style={{
                height: 65,
                width: '100%',
                padding: '5px 15px',
                position: 'absolute',
                borderTop: '1px solid #e3e3e3',
                backgroundColor: '#FFF',
                zIndex: 99,
                right: 0,
                bottom: 0
              }}>
              {
                actions.map((action) => {
                  const {onClick, label, type, mode} = action;
                  if (mode === 'delete') {
                    return (
                      <Popconfirm 
                        title="确定删除吗?"
                        icon={<QuestionCircleTwoTone />} 
                        onConfirm={() => {
                          setLoading(true);
                          const evt = new Promise((resolve) => {
                            if (onClick) {
                              resolve(onClick());
                            }
                            return resolve(true);
                          });
                          evt.then(() => {
                            setVisible(false);
                          }, () => setLoading(false));
                        }}
                        okText="确定"
                        cancelText="取消">
                        <Button
                          loading={loading}
                          className="margin-right"
                          type={type}>
                          {label}
                        </Button>
                      </Popconfirm>
                    );
                  }
                  return (
                    <Button
                      loading={loading}
                      className="margin-right"
                      onClick={() => {
                        setLoading(true);
                        const evt = new Promise((resolve) => {
                          if (onClick) {
                            resolve(onClick());
                          }
                          return resolve(true);
                        });
                        evt.then(() => {
                          setVisible(false);
                        }, () => setLoading(false));
                      }}
                      type={type}>
                      {label}
                    </Button>
                  );
                })
              }
            </div>
          ) : ''
        }
      </Drawer>
    );
  };
  if (manager) {
    manager.pop();
    manager.push(DrawerFun);
  }
};

export default showDraw;
