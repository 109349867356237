import _ from 'lodash';

import { Breadcrumb as AntBreadcrumb } from 'antd';
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
  matchPath,
} from 'react-router-dom';
const AntBreadcrumbItem = AntBreadcrumb.Item;

const renderRoute = ({ route, parent, pathname }) => {
  const { path, breadcrumbName, element, children } = route;
  const tidyPath = `${path}`.replace(/^\//, '').replace(/\/$/, '');
  const current = parent.concat([tidyPath]); // snippet
  const fullPath = current.join('/');
  const isEnd = fullPath.split('/').length === pathname.split('/').length;

  const match = matchPath({ path: fullPath, end: isEnd }, pathname);
  const matched = _.get(match, 'pattern.end') === false && element;

  return (
    <Route
      key={tidyPath}
      path={tidyPath}
      element={[
        <AntBreadcrumbItem key={tidyPath}>
          {matched && <Link to={match!.pathname}>{breadcrumbName}</Link>}
          {!matched && breadcrumbName}
        </AntBreadcrumbItem>,
        <Outlet key={`outlet_${tidyPath}`} />,
      ]}
    >
      {_.map(children, (route) =>
        renderRoute({ route, parent: current, pathname })
      )}
    </Route>
  );
};

interface BreadcrumbProps {
  routes: any[];
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { routes } = props;
  const { pathname } = useLocation();

  return (
    <AntBreadcrumb>
      <Routes>
        {_.map(routes, (route) => renderRoute({ route, parent: [], pathname }))}
      </Routes>
    </AntBreadcrumb>
  );
};

export default Breadcrumb;
