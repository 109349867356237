const authSDK = (base, account, project) => {
  const serve = `${base}account/auth/`;

  return {
    signin: (paload) => {
      const options = {
        body: JSON.stringify(paload),
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      };

      return fetch(`${base}accounts/${paload['account']}/projects/default/users/${paload['username']}/login/${paload['password']}/`, {}).then((response) => {
        const status = response.status;
        if (status >= 200 && status <= 300) return response.json();
        return Promise.reject(
          response.json().then((json) => ({ response, ...json }))
        );
      });
    },
    createKeys: (paload) => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      };
      return fetch(`${base}accounts/${paload['account_name']}/projects/default/users/${paload['user_name']}/access_keys/`, options).then((response) => {
        const status = response.status;
        console.log('response', response)
        if (status >= 200 && status <= 300) return response.json();
        return Promise.reject(
          response.json().then((json) => ({ response, ...json }))
        );
      });
      
      // /accounts/<res_account_name>/projects/default/accessKeys/<access_key_id>/
    },
    getKeys: (paload) => {
      const options = {
        method: 'get',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      };
      return fetch(`${base}accounts/${paload['account_name']}/projects/default/users/${paload['user_name']}/access_keys/`, {}).then((response) => {
        const status = response.status;
        console.log('response', response)
        if (status >= 200 && status <= 300) return response.json();
        return Promise.reject(
          response.json().then((json) => ({ response, ...json }))
        );
      });
    },
    getKeyId: (paload, access_key_id) => {
      return fetch(`${base}accounts/${paload['account_name']}/projects/accessKeys/${access_key_id}/`, {}).then((response) => {
        const status = response.status;
        console.log('response', response)
        if (status >= 200 && status <= 300) return response.json();
        return Promise.reject(
          response.json().then((json) => ({ response, ...json }))
        );
      });
    },
  };
};

export default authSDK;
