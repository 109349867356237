import GDRequest from '@common/gdrequest';
import _ from 'lodash';

const projectSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/projects/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/projects/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/projects/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/projects/${id}/`, payload).send(),
    types: () => GDRequest.get(base, `${serve}/projects/types/`).send(),
    statuses: () => GDRequest.get(base, `${serve}/projects/statuses/`).send(),
    addTypes: (array) =>
      Promise.all(
        _.map(array, (o) =>
          GDRequest.post(base, `${serve}/projects/types/`, o).send()
        )
      ),
    addStatuses: (array) =>
      Promise.all(
        _.map(array, (o) =>
          GDRequest.post(base, `${serve}/projects/statuses/`, o).send()
        )
      ),
    relations: (id, payload) => {
      const formData = new FormData();
      formData.append('case_numbers', payload);
      return GDRequest.post(
        base,
        `${serve}/projects/${id}/cases/relations/`,
        formData
      ).sendForm();
    },
    relationsByFile: (id, file) => {
      const formData = new FormData();
      formData.append('case_file', file);
      return GDRequest.post(
        base,
        `${serve}/projects/${id}/cases/relations/`,
        formData
      ).sendForm();
    },
    // 导出
    export: (payload) => GDRequest.get(base, `${serve}/projects/export/`, payload).blob().send(),
    exportList: (query) => GDRequest.get(base, `${serve}/projects/export/`, query).send(),
    // get file
    getFile: (filename) => GDRequest.get(base, `${serve}/files/${filename}/`).blob().send(),
    delFile: (filename) => GDRequest.del(base, `${serve}/files/${filename}/`).send(),
    delFilePost: (filename) => GDRequest.del(base, `${serve}/files/${filename}/delete/`).send(),
    getConfigList: (query) => GDRequest.get(base, `${serve}/projects/partners/`, query).send(),
    createConfig: (data) => GDRequest.post(base, `${serve}/projects/partners/`, data).send(),
    updateConfig: (id, payload) => GDRequest.put(base, `${serve}/projects/partners/${id}/`, payload).send(),
    deleteConfig: (id) => GDRequest.post(base, `${serve}/projects/partners/${id}/delete/`).send(),
    getLog: (id) => {const url = `${serve}/projects/${id}/loggers/`; return (query) => GDRequest.get(base, url, query).send()},
    addLog: (id, payload) => GDRequest.post(base, `${serve}/projects/${id}/loggers/`, payload).send(),
    updateAuditing: (data) => GDRequest.put(base, `${serve}/projects/auditing/status/`, data).send(),
    getStatistics: (id) => GDRequest.get(base, `${serve}/projects/${id}/statistics/`).send(),
    addCase: (id, payload) => GDRequest.post(base, `${serve}/projects/${id}/cases/relations/`, payload).send(),
    caseList: (id) => {const url = `${serve}/projects/${id}/cases/relations/`; return (query) => GDRequest.get(base, url, query).send()},
  };
};
export default projectSDK;
