import GDRequest from '@common/gdrequest';

const websiteSDK = (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    addBackgroundColor: (data) => GDRequest.post(base, `${serve}/website/background/`, data).send(),
    getBackgroundColor: () => GDRequest.get(base, `${serve}/website/background/`).send(),
    getHome: (query) => GDRequest.get(base, `${serve}/website/homepath/`, query).send(),
    addBanners: (data) => GDRequest.post(base, `${serve}/website/banners/`, data).send(),
    getBanners: () => GDRequest.get(base, `${serve}/website/banners/`).send(),
    addContent: (data) => GDRequest.post(base, `${serve}/website/contents/`, data).send(),
    getContents: (query) => GDRequest.get(base, `${serve}/website/contents/`, query).send(),
    getContentDetail: (content_id) => GDRequest.get(base, `${serve}/website/contents/${content_id}/`).send(),
    updateContent: (content_id, data) => GDRequest.put(base, `${serve}/website/contents/${content_id}/`, data).send(),
    deleteContent: (content_id) => GDRequest.del(base, `${serve}/website/contents/${content_id}/`).send(),
    deleteContentPost: (content_id) => GDRequest.post(base, `${serve}/website/contents/${content_id}/delete/`).send(),
    getSummaries: () => GDRequest.get(base, `${serve}/website/summaries/`).send(),
    getSummarieDetail: (summary_id) => GDRequest.get(base, `${serve}/website/summaries/${summary_id}/`).send(),
    updateSummarie: (summary_id, data) => GDRequest.put(base, `${serve}/website/summaries/${summary_id}/`, data).send(),
  };
};
export default websiteSDK;
