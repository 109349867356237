import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

export const pagedataSlice = createSlice({
  name: 'pagedata',
  initialState: {} as any,
  reducers: {
    updatePageData: (state, action) => {
      const route = _.get(action, 'payload.path');
      if (route) {
        _.set(state, `${route}.data`, _.get(action, 'payload.data'));
      }
    },
    pageLoading: (state, action) => {
      const route = _.get(action, 'payload.path');
      if (route) {
        _.set(state, `${route}.loading`, _.get(action, 'payload.data'));
      }
    }
  },
});

export const { updatePageData, pageLoading } =
pagedataSlice.actions;
export default pagedataSlice.reducer;
